import jwtDecode from "jwt-decode";
import { Navigate, Outlet } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { getUnlogged } from "../redux/loginSlice";
import { useEffect, useState } from "react";

const checkToken = (token) => {
    try {
        const decodedToken = jwtDecode(token);
        const currentTime = Date.now() / 1000;
        return decodedToken.exp > currentTime;
    } catch (error) {
        return false;
    }
}

const ProtectedRoute = ({ children }) => {

    // Cath url queries
    const [queryString, setQueryString] = useState(null);

    useEffect(() => {
        const url = window.location.href;
        setQueryString(url);
    }, [])

    const dispatch = useDispatch();
    const token = localStorage.getItem('token');
    if (token && checkToken(token)) {
        return (
            <>
                <Outlet />
                {children}
            </>
        );
    } else {
        if (queryString) {
            dispatch(getUnlogged());
            return <Navigate to="/login" state={{ redirect: encodeURIComponent(queryString) }} replace />
        }
    }

}

export default ProtectedRoute;