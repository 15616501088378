import { useEffect, useState, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import FetchLoader from "../components/FetchLoader";
import { GoConversationsButton, SecondaryButton, TealButton } from "../components/Buttons";
import MessageCard from "../components/inboxComponents/MessageCard";
import { useDispatch, useSelector } from "react-redux";
import MessageSendForm from "../components/inboxComponents/MessageSendForm";
import ProductMiniCard from "../components/ProductMiniCard";
import { updateRemoveAlert } from "../redux/loginSlice";

const ConversationPage = () => {
    const { id } = useParams();
    const userId = useSelector(state => state.login.id)

    // get Conversation data
    const [data, setData] = useState(null);
    const [page, setPage] = useState(1);
    const [fetchStatus, setFetchStatus] = useState('idle');
    const [error, setError] = useState(null)
    const token = localStorage.getItem('token');
    const getConversationData = async () => {
        try {
            const url = `${process.env.REACT_APP_BASE_URL}/conversations/get-conversation`;
            setFetchStatus('loading');
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ conversationId: id, page: page })
            });
            if (response.ok) {
                const dataResult = await response.json();
                if (!data) {
                    setData(dataResult);
                } else {
                    // Dalla seconda paginata in poi, aggiunge gli elementi nuovi del campo messages e li aggiunge (prima) di quelli esistenti
                    setData(prevData => ({
                        ...prevData,
                        conversation: {
                            ...prevData.conversation,
                            messages: dataResult.conversation.messages.concat(prevData.conversation.messages)
                        }
                    }))
                }
                setFetchStatus('succeeded');
            } else {
                const errorResult = await response.json();
                setFetchStatus('rejected');
                setError(errorResult)
            }
        } catch (error) {
            setError(error)
        }
    }



    // Set receiverId
    const [receiverId, setReceiverId] = useState(null)
    useEffect(() => {
        if (data) {
            if (userId === data.conversation.supplierUser._id) {
                setReceiverId(data.conversation.applicantUser._id)
            } else if (userId === data.conversation.applicantUser._id) {
                setReceiverId(data.conversation.supplierUser._id)
            }
        }
    }, [data])

    useEffect(() => {
        if (id && userId) {
            getConversationData();
        }
    }, [id, userId])

    // Handle isMyProduct
    const [isMyProduct, setIsMyProduct] = useState(null)
    useEffect(() => {
        if (data) {
            if (data.conversation.supplierUser._id === userId) {
                setIsMyProduct(true)
            } else {
                setIsMyProduct(false)
            }
        }
    }, [data])

    // Handle request sended
    const [isRequestSended, setIsRequestSended] = useState(false);
    useEffect(() => {
        if (fetchStatus === 'succeeded') {
            console.log('here 1')
            if (data.conversation.objectReference.saleStatus.status === 'Required') {
                console.log('here 2')
                if (data.conversation.objectReference.saleStatus.pickerId === userId) {
                    console.log('here 3')
                    setIsRequestSended(true);
                }
            }
        }
    }, [data])

    // debug
    useEffect(() => {
        console.log('isRequestSended: ', isRequestSended)
    }, [isRequestSended])

    useEffect(() => {
        console.log('fetchStatus: ', fetchStatus)
    }, [fetchStatus])

    // Handle error
    useEffect(() => {
        if (error) {
            console.log('error: ', error) // Implementa un sistema di visualizzazione dell'errore nella pagina (questo è importante anche per il resto della piattagorma)
        }
    }, [error])

    // Automatic scroll to bottom
    const scrollableRef = useRef();

    useEffect(() => {
        // Posiziona lo scroll in fondo al caricamento della pagina
        if (scrollableRef.current) {
            scrollableRef.current.scrollTop = scrollableRef.current.scrollHeight;
        }
    }, [data]);

    // Product mini-card click
    const navigate = useNavigate();
    const miniCardClick = () => {
        navigate(`/product-page/${data.conversation.objectReference._id}/${data.conversation.objectReference.distance}`)
    }

    // Handle adding page
    useEffect(() => {
        console.log('page: ', page)
        getConversationData();
    }, [page])

    // DEBUG
    useEffect(() => {
        console.log('data: ', data)
    }, [data])

    // Remove conversation alert from Redux
    const { newConversationAlert } = useSelector(state => state.login);
    const dispatch = useDispatch();
    useEffect(() => {
        if (newConversationAlert) {
            removeAlertFetch();
        }
    }, [newConversationAlert])
    const removeAlertFetch = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/conversations/remove-alert?userId=${userId}&conversationId=${id}`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });
            if (response.ok) {
                dispatch(updateRemoveAlert(id));
            } else {
                const errorResult = await response.json();
                console.log('Fetch error: ', errorResult)
            }
        } catch (error) {
            console.log('Catch error: ', error)
        }
    }

    // Send request fetch
    const [isBuying, setIsBuying] = useState(false)
    const [requestFetchStatus, setRequestFetchStatus] = useState('idle');
    const [requestError, setRequestError] = useState(null);
    const sendRequest = async () => {
        setRequestFetchStatus('loading');
        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/products/send-request?productId=${data.conversation.objectReference._id}&pickerId=${userId}`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });
            if (response.ok) {
                const result = await response.json();
                setRequestFetchStatus('succeeded');
                setTimeout(() => {
                    window.location.herf = "/"
                }, 3000)
            } else {
                const errorResult = await response.json();
                setRequestError(errorResult);
                setRequestFetchStatus('error');
            }
        } catch (error) {
            setRequestError(error);
            setRequestFetchStatus('error');
        }
    }

    return (
        <div>
            <div className="main-container">
                <GoConversationsButton />
                {data && <h4>Id conversazione: {data.conversation._id}</h4>}
                <h3 className="mb-[-25px] text-slate-400">Oggetto della conversazione:</h3>
                {data && !isMyProduct &&
                    <Link to={`/product-page/${data.conversation.objectReference._id}`}><ProductMiniCard
                        photo={data.conversation.objectReference.photo.resizedUrl}
                        title={data.conversation.objectReference.title}
                        category={data.conversation.objectReference.category}
                        price={data.conversation.objectReference.price}
                    /></Link>
                }
                {data && isMyProduct &&
                    <ProductMiniCard
                        photo={data.conversation.objectReference.photo.resizedUrl}
                        title={data.conversation.objectReference.title}
                        category={data.conversation.objectReference.category}
                        price={data.conversation.objectReference.price}
                        isMine={true}
                    />
                }

                {/* Buying object box */}
                {isRequestSended && <h3 className="border-2 border-teal-400 rounded p-2">Hai già inviato una richiesta per questo prodotto.</h3>}
                {!isRequestSended && !isMyProduct && !isBuying && <TealButton text="Acquista questo oggetto" click={() => { setIsBuying(true) }} />}
                {!isMyProduct && isBuying && requestFetchStatus === 'idle' &&
                    <div className="bg-teal-100 p-4 border-2 border-teal-500 rounded flex flex-col items-center">
                        <div className="text-center">
                            <h4>Confermando invierai all'utente proprietario una richiesta per l'acquisto di quest'oggetto.</h4>
                            <p>Se il proprietario dovesse accettare potrà utilizzare il tuo numero di telefono o la tua email per contattarti in modo che vi accordiate in privato per lo scambio e il pagamento.</p>
                        </div>
                        <div className="flex gap-2">
                            <TealButton text="Conferma la richiesta" click={sendRequest} />
                            <SecondaryButton text="Annulla" click={() => { setIsBuying(false) }} />
                        </div>
                    </div>

                }
                {requestFetchStatus === 'loading' && <FetchLoader />}
                {requestFetchStatus === 'succeeded' &&
                    <>
                        <h2>Richiesta di acquisto inviata</h2>
                        <p>Attendi che il proprietario dell'oggetto ti contatti per telefono o via email.</p>
                        <h3>Verrai reindirizzat* in homepage fra 3 secondi.</h3>
                    </>
                }
                {
                    requestFetchStatus === 'error' &&
                    <>
                        <h3>Qualcosa è andato storto. Ricarica la pagina e riprova</h3>
                    </>
                }



                {/* Chat story box */}
                <h3 className="mb-[-25px] text-slate-400">Messaggi scambiati:</h3>
                <div className="flex flex-col w-full p-4 md:w-[800px] border-2 rounded gap-2 items-center h-[400px] md:h-[400px] overflow-y-scroll" ref={scrollableRef}>
                    {fetchStatus === 'succeeded' && <div className="border h-fit w-fit rounded-lg border-slate-700 p-2 cursor-pointer mr-12" onClick={() => setPage(page + 1)}><h4>Messaggi meno recenti...</h4></div>}
                    {fetchStatus === 'idle' || fetchStatus === 'loading' && <FetchLoader />}
                    {fetchStatus === 'error' && <h3>Qualcosa è andato storto, ricarica la pagina e riprova</h3>}
                    {fetchStatus === 'succeeded' &&
                        data &&
                        data.conversation.messages.map((element, index) => {
                            return <MessageCard key={index} userId={userId} messageData={element} />
                        })
                    }

                </div>
                {isMyProduct &&
                    <div className={`mt-[-20px] w-fit rounded flex p-1 bg-slate-200 text-xs justify-center`}>
                        Dopo aver chiarito i dubbi riguardo il prodotto, se l'utente vuole acquistarlo ricordagli di inviarti la richiesta per quell'oggetto (soltanto così verrà tolto dai tuoi prodotti in vendita).
                    </div>}
                {!isMyProduct && <div className={`mt-[-20px] w-fit rounded flex p-1 bg-slate-200 text-xs justify-center`}>
                    Chiarisci ogni dubbio sull'oggetto in vendita. Quando sei convint* puoi inviare una richiesta di acquisto cliccando su "Acquista questo oggetto".
                </div>}

                {/* Input box */}
                {data && receiverId && <MessageSendForm
                    convId={data.conversation._id}
                    senderId={userId}
                    receiverId={receiverId}
                />}
            </div>
        </div>
    )
}

export default ConversationPage;