import { useSelector } from "react-redux";
import { GoHomeButton } from "../components/Buttons.jsx";
import MyProductCard from "../components/myProfileProducts/MyProductCard.jsx";
import SoldProductCard from "../components/myProfileProducts/SoldProductCard.jsx";
import BuyedProductCard from "../components/myProfileProducts/BuyedProductCard.jsx";
import { useState, useEffect } from "react";
import FetchLoader from "../components/FetchLoader.jsx";
import RequestProductCard from "../components/myProfileProducts/RequestProductCard.jsx";
import MyUserData from "../components/myProfileProducts/MyUserData.jsx";
import MyProfileNavbar from "../components/myProfileProducts/MyProfileNavbar.jsx";
import MyRequiredProductCard from "../components/myProfileProducts/MyRequiredProductCard.jsx";

const MyProfile = () => {
    const reduxUserProducts = useSelector((state) => state.login.products);
    const reduxUserLogin = useSelector((state) => state.login);
    const reduxUserPosition = useSelector((state) => state.position)

    // get purchases
    const token = localStorage.getItem('token');
    const [fetchStatus, setFetchStatus] = useState('idle');
    const [purchases, setPurchases] = useState(null);
    const findPurchases = async () => {
        try {
            setFetchStatus('pending')
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/products/find-purchases?buyerId=${reduxUserLogin.id}&lat=${reduxUserPosition.lat}&lng=${reduxUserPosition.lng}`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });
            if (response.ok) {
                const data = await response.json();
                setPurchases(data.payload);
                setFetchStatus('succeeded')
            } else {
                const dataError = await response.json();
                console.error('Errore nella richiesta.', dataError)
                setFetchStatus('error');
            }
        } catch (error) {
            console.error('Catch error: ', error);
            setFetchStatus('error');
        }
    }
    useEffect(() => {
        if (reduxUserLogin.id && reduxUserPosition.lat) {
            findPurchases();
        }
    }, [reduxUserLogin.id, reduxUserPosition])

    // handle section
    const selectedSection = useSelector((state) => state.mySection.selected);
    return (
        <div className="main-container !pt-0">

            <MyProfileNavbar />

            <GoHomeButton />
            {selectedSection === 0 && <h2>Il tuo profilo</h2>}
            {selectedSection === 1 && <h2>I tuoi prodotti in vendita</h2>}
            {selectedSection === 2 && <h2>Richieste per i tuoi prodotti</h2>}
            {selectedSection === 3 && <h2>I tuoi prodotti venduti</h2>}
            {selectedSection === 4 && <h2>I tuoi prodotti acquistati</h2>}
            {selectedSection === 5 && <h2>Le tue richieste</h2>}
            <div className="flex flex-col lg:flex-row gap-2">
                <div className="box gap-8 md:w-[600px] md:shadow-2xl">

                    {selectedSection === 0 && <MyUserData reduxLoginData={reduxUserLogin} />}

                    {selectedSection === 1 &&
                        <>
                            {reduxUserProducts && reduxUserProducts.map((product, index) => {
                                if (product.saleStatus.status === 'Available') {
                                    return <MyProductCard key={index} productData={product} />
                                }
                            })}
                        </>
                    }

                    {selectedSection === 2 &&
                        <>
                            {reduxUserProducts && reduxUserProducts.map((product, index) => {
                                if (product.saleStatus.status === 'Required') {
                                    return <MyRequiredProductCard key={index} productData={product} />
                                }
                            })}
                        </>
                    }

                    {selectedSection === 3 &&
                        <>
                            {reduxUserProducts && reduxUserProducts.map((product, index) => {
                                if (product.saleStatus.status === 'Sold') {
                                    return <SoldProductCard key={index} productData={product} />
                                }
                            })}
                        </>
                    }

                    {selectedSection === 4 &&
                        <>
                            {fetchStatus === 'pending' ? (
                                <FetchLoader />
                            ) : (
                                fetchStatus === 'error' ? (
                                    <h3>Qualcosa è andato storto, ricarica la pagina e riprova.</h3>
                                ) : (
                                    fetchStatus === 'succeeded' && purchases.map((product, index) => {
                                        if (product.saleStatus.status === 'Sold') {
                                            return <BuyedProductCard key={index} productData={product} />
                                        }
                                    })
                                )
                            )}
                        </>
                    }

                    {selectedSection === 5 &&
                        <>
                            {fetchStatus === 'pending' ? (
                                <FetchLoader />
                            ) : (
                                fetchStatus === 'error' ? (
                                    <h3>Qualcosa è andato storto, ricarica la pagina e riprova.</h3>
                                ) : (
                                    fetchStatus === 'succeeded' && purchases.map((product, index) => {
                                        if (product.saleStatus.status === 'Required') {
                                            return <RequestProductCard key={index} productData={product} />
                                        }
                                    })
                                )
                            )}
                        </>
                    }

                </div>

            </div>


        </div>
    )
}

export default MyProfile;