import { useDispatch, useSelector } from "react-redux";
import Map from "../components/Map";
import { getPositionAsync } from "../redux/positionSlice";
import FetchLoader from '../components/FetchLoader';
import { GoHomeButton } from "../components/Buttons";
import ProductsAmount from "../components/ProductsAmount";
import { useEffect } from "react";
import { getMapProducts } from "../redux/mapProductsSlice";
import PanelCardsContainer from "../components/cardsContainers/PanelCardsContainer";

const ProductsMapPage = () => {

    // Rilevo la posizione dell'utente al click sul button
    const { isPosition, lat, lng, country, isLoading } = useSelector(state => state.position);
    const dispatch = useDispatch();
    const handleButtonBlick = () => {
        dispatch(getPositionAsync())
    }

    // A posizione rilevata, mando la fetch per i prodotti
    useEffect(() => {
        if (isPosition && lat && lng) {
            dispatch(getMapProducts({ lat, lng }))
        }
    }, [isPosition, lat, lng])

    // Eseguo subito la chiamata fetch per i prodotti che hanno la posizione
    const { data } = useSelector(state => state.mapProducts);
    useEffect(() => {
        dispatch(getMapProducts({ lat: null, lng: null }))
    }, [])

    return (
        <div className="main-container">
            <GoHomeButton />
            {data && <ProductsAmount number={data.totalElements} />}
            <div className="w-full px-2 flex flex-col items-center gap-4">

                <div className="w-full flex justify-center mt-8">
                    {
                        !isPosition && !isLoading &&
                        <div className="flex flex-col items-center">
                            <button className={`bg-teal-400 text-slate-50 px-4 py-3 rounded-lg hover:bg-teal-500 flex gap-2 justify-center w-full md:w-fit`} onClick={handleButtonBlick}>
                                <div className="pt-0.5"><i class="fi fi-rr-location-crosshairs text-white"></i></div>
                                <div>Rileva la mia posizione</div>
                            </button>
                            <p>oppure</p>
                            <h3>Esplora la mappa per trovare i prodotti</h3>
                        </div>

                    }
                    {
                        isLoading && !isPosition && <FetchLoader />
                    }
                    {
                        isPosition && !isLoading &&
                        <div className="flex flex-col gap-4 items-center w-96 border-2 border-slate-500 rounded-xl p-4">
                            <h3>La tua posizione:</h3>
                            <div className="flex flex-col w-full">
                                <div className="p-2 bg-white rounded-full"><b>{lat}, {lng}</b></div>
                                <p className="text-teal-500">{country}</p>
                            </div>
                        </div>
                    }

                </div>

                <Map />

                {
                    data &&
                    <div className="flex flex-col gap-16">
                        <ProductsAmount number={data.totalElements} />
                        <PanelCardsContainer objectArray={data.products} isDistance />
                    </div>
                }

            </div>

        </div>
    )
}

export default ProductsMapPage