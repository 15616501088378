import { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { updateFavourites } from "../redux/loginSlice";

const AddToFavourites = ({ productId }) => {
    const dispatch = useDispatch();
    const userId = useSelector((state) => state.login.id);
    const reduxUserFavourites = useSelector((state) => state.login.favourites);
    const [status, setStatus] = useState('unclicked')
    const token = localStorage.getItem('token');

    useEffect(() => {
            if(reduxUserFavourites && reduxUserFavourites.includes(productId)) {
                setStatus('clicked')
            }
    }, [reduxUserFavourites])


    const sendNewFavourite = async () => {
        try {
            setStatus('pending');
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/users/add-new-favourite`, {
                method: 'PATCH',
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ userId, productId })
            });
            const object = await response.json();
            if (response.ok) {
                dispatch(updateFavourites(object));
                console.log('UPDATE object (new): ', object)
                setStatus('clicked');
            } else {
                console.log('Object error: ', object)
                setStatus('unclicked')
            }
        } catch (error) {
            console.error('Catch error: ', error)
            setStatus('unclicked')
        }
    }

    const sendRemoveFavourite = async () => {
        try {
            setStatus('pending');
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/users/remove-favourite`, {
                method: 'PATCH',
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ userId, productId })
            });
            const object = await response.json();
            if(response.ok) {
                dispatch(updateFavourites(object));
                console.log('UPDATE object (remove): ', object)
                setStatus('unclicked');
            } else {
                console.log('Object error: ', object)
                setStatus('clicked')
            }
        } catch (error) {
            console.error('Catch error: ', error)
            setStatus('clicked')
        }
    }

    const handleClick = () => {
        if(status === 'unclicked') {
            sendNewFavourite();
        } else if (status === 'clicked') {
            sendRemoveFavourite();
        }
    }

    return (
        <div
            className='mt-2 cursor-pointer w-[30px]'
            onClick={handleClick}
        >
            {
                status === 'clicked' ? (
                    <i className="fi fi-sr-star text-teal-300 text-2xl mb-1"></i>
                ) : (

                    status === 'pending' ? (
                        <i className="fi fi-br-hourglass-end text-teal-300 text-2xl mb-1"></i>
                    ) : (
                        status === 'unclicked' && <i className="fi fi-rr-star text-teal-300 text-2xl mb-1" ></i>
                    )
                )
            }
        </div>
    )
}

export default AddToFavourites;