import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { GoHomeButton, PrimaryButton, SecondaryButton } from '../components/Buttons.jsx';
import { useDispatch, useSelector } from 'react-redux';
import { clearError, register } from '../redux/loginSlice.js';

import logo from '../images/tattooswap_line.png';
import FetchLoader from '../components/FetchLoader.jsx';
import conditionDocument from '../images/doc/condition.docx';
import privacyDocument from '../images/doc/privacy.docx'

const RegisterPage = () => {

    // GET POSITION DATA
    const reduxUserPosition = useSelector((state) => state.position);

    //----- GESTIONE INPUT UTENTE -----//
    const [isEmailWrong, setIsEmailWrong] = useState(false);
    const [isPasswordWrong, setIsPasswordWrong] = useState(false);
    const [repeatPassword, setRepeatPassword] = useState('');

    const initialState = {
        name: '',
        surname: '',
        email: '',
        password: '',
        basePosition: null
    }

    //--- inputData con controllo email e numero di telefono ---//
    function isEmailValid(mailInput) {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(mailInput);
    }

    const [inputData, setInputData] = useState(initialState)

    const handleInputData = (event) => {
        const { id, value } = event.target;

        if (id === 'email') {
            if (isEmailValid(value)) {
                setIsEmailWrong(false);
            } else {
                setIsEmailWrong(true);
            }
        }

        if (id === 'password') {
            if (value.length > 7) {
                setIsPasswordWrong(false);
            } else {
                setIsPasswordWrong(true);
            }
        }

        if (checkData) { setCheckData(false) };

        setInputData(prevInputData => ({
            ...prevInputData,
            [id]: value
        }))

    }

    const handleRepeatPassword = (event) => {
        const value = event.target.value;
        setRepeatPassword(value);
    }

    useEffect(() => {
        if (reduxUserPosition.lat !== null || reduxUserPosition.lng !== null) {
            setInputData(prevInputData => ({
                ...prevInputData,
                basePosition: reduxUserPosition
            }))
        }
    }, [reduxUserPosition])

    //----- SEND DATA FETCH AND HOMEPAGE REDIRECT -----//

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { isLogged, isLoading, error } = useSelector((state) => state.login);
    const [checkData, setCheckData] = useState(false);
    const [isCheckCondition, setIsCheckCondition] = useState(false)
    const sendData = async () => {

        setCheckData(false);
        if (
            (inputData.name) &&
            (inputData.surname) &&
            (inputData.email && !isEmailWrong) &&
            (inputData.password && !isPasswordWrong) &&
            (inputData.password === repeatPassword) &&
            isCheckCondition
        ) {
            dispatch(register(inputData));
        } else {
            setCheckData(true);
        }
    }

    if (isLogged) {
        setTimeout(() => {
            navigate('/homepage');
            window.location.reload();
        }, 5000);
    }

    if (error) {
        console.log('error: ', error);
    }

    // Handle documents download
    /* const handleConditionDownload = (event) => {
        event.preventDefault();
        const link = document.createElement('a');
        link.href = conditionDocument;
        link.download = 'condition.docx';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };
    const handlePrivacyDownload = (event) => {
        event.preventDefault();
        const link = document.createElement('a');
        link.href = privacyDocument;
        link.download = 'privacy.docx';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }; */

    return (
        <div className="main-container">
            <GoHomeButton />
            <div className="box md:shadow-2xl">

                {
                    isLogged ? (

                        <div>
                            <h3>I tuoi dati sono stati inseriti correttamente.</h3>
                            <h3 className='text-teal-400'>Benvenuto su Tattooswap!</h3>
                            <p>Verrai indirizzato alla homepage fra 5 secondi...</p>
                        </div>

                    ) : (
                        error ? (


                            error === 'Failed to fetch' ? (
                                <>
                                    <h4 className='mb-4'>Qualcosa è andato storto...</h4>
                                    <SecondaryButton text="Ricarica la pagina" click={() => window.location.reload(true)} />

                                </>
                            ) : (
                                error === 'Utente già registrato. Se sei tu, puoi eseguire il login' ? (
                                    <>
                                        <h4 className='mb-4'>{error}</h4>
                                        <SecondaryButton text="Vai al login" click={() => { dispatch(clearError()); navigate('/login') }} />

                                    </>
                                ) : (
                                    <>
                                        <h4 className='mb-4'>{error}</h4>
                                        <SecondaryButton text="Ricarica la pagina" click={() => window.location.reload(true)} />
                                    </>
                                )
                            )



                        ) : (
                            <>
                                <div className='flex flex-col'>
                                    <h3 className="mb-4">Unisciti a </h3>
                                    <img src={logo} alt="Tattooswap Logo" className='h-12 mt-[-4px] ml-4' />
                                </div>

                                <h4 className='mt-8'>Registrati compilando i campi qui sotto</h4>
                                <label htmlFor="name">Nome</label>
                                <input type="text" id="name" onChange={handleInputData} />

                                <label htmlFor="surname">Cognome</label>
                                <input type="text" id="surname" onChange={handleInputData} />

                                <label htmlFor="email">Email</label>
                                <input type="text" id="email" onChange={handleInputData} />
                                <div className={isEmailWrong ? 'text-red-500 text-xs self-start' : 'text-slate-500 text-xs self-start'}>Inserisci un'email valida.</div>

                                <label htmlFor="password">Password</label>
                                <input type="password" id="password" onChange={handleInputData} />
                                <div className={isPasswordWrong ? 'text-red-500 text-xs self-start mb-8' : 'text-slate-500 text-xs self-start'}>Inserisci una password di almeno 8 caratteri.</div>

                                <label htmlFor="repeatPassword">Ripeti password</label>
                                <input type="password" id="repeatPassword" onChange={handleRepeatPassword} />
                                <div className={repeatPassword !== inputData.password ? 'text-red-500 text-xs self-start mb-8' : 'text-slate-500 text-xs self-start mb-8'}>Ripeti la password.</div>

                                
                                <div className='flex items-start gap-2 mb-8 border border-teal-500 p-4'>
                                    <input type="checkbox" className='text-s w-fit mt-[6px]' checked={isCheckCondition} onChange={() => setIsCheckCondition(!isCheckCondition)} />
                                    <label className='mt-0'>Ho letto e accetto le <Link to="/service-condition"><span className='text-teal-500'>Condizioni del Servizio</span></Link> e la <Link to="/privacy-policy"><span className='text-teal-500'>Privacy Policy</span></Link></label>
                                </div>



                                {checkData && <h3>Ricontrolla i dati inseriti.</h3>}
                                {checkData && <p>(Ricordati di accettare le condizioni)</p>}
                                {
                                    isLoading ? (
                                        <FetchLoader />
                                    ) : (
                                        <PrimaryButton text="Invia dati" click={sendData} />
                                    )
                                }
                            </>
                        )

                    )

                }


            </div >

        </div >
    )
}

export default RegisterPage;