import { Link } from 'react-router-dom';
import AddToFavourites from './AddToFavourites';
import { useSelector } from 'react-redux';

const ProductCard = ({ productData, isDistance }) => { // isDistance è un booleano che serve a mostrare sopra la card la distanza dall'utente
    const isUserLogged = useSelector((state) => state.login.isLogged)
    return (
        <div className={`card-2xs-width w-[240px] h-[290px] sm:w-[200px] 2xl:w-[180px] flex-none mb-6`}>

            <Link to={`/product-page/${productData._id}`}>
                {/* Photo */}
                <div className="card-2xs-width card-2xs-height w-[240px] h-[240px] sm:w-[200px] sm:h-[200px] 2xl:w-[180px] 2xl:h-[180px] md:hover:translate-y-[-8px] transition-transform duration-300 ease-in-out rounded-lg overflow-hidden relative shadow-xl">
                    {isDistance && productData.distance && <h4 className='text-xs text-left'>A <span className='text-red-400'>{Math.round(productData.distance / 1000)}</span> km da te.</h4>}
                    {/* {productData.distance && <h4 className='text-xs text-left'>A <span className='text-red-400'>{Math.round(productData.distance / 1000)}</span> km da te.</h4>} */}
                    <img src={productData.photo.resizedUrl} alt="product photo" className='rounded-lg w-full h-full object-cover group' />
                    {!isUserLogged && <div className='absolute bottom-0 left-0 right-0 bg-teal-500 flex justify-center items-center text-white'>
                        <i class="fi fi-rr-lock mt-2"></i> <span className='text-white ml-2'>Esegui il login</span>
                    </div>}
                </div>
            </Link >

            {/* Caption */}
            <div className='flex justify-between px-2 w-full'>

                <Link to={`/product-page/${productData._id}/${productData.distance}`}>
                    <div className='text-left w-[120px]'>
                        <h3>{productData.price.toFixed(2)}€</h3>
                        <div className='max-h-[26px] overflow-y-hidden'>
                            <h4>{productData.title}</h4>
                        </div>
                        <span className='text-sm'>{productData.category}</span>
                    </div>
                </Link>

                {isUserLogged && <AddToFavourites productId={productData._id} />}

            </div>
        </div>
    )
}

export default ProductCard;