import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { PrimaryButton, SecondaryButton, TealButton } from "../Buttons";
import { getUnlogged } from "../../redux/loginSlice";
import ProfileUserAvatar from "../ProfileUserAvatar";
import { Link } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import FetchLoader from "../FetchLoader";

const MyUserData = ({ reduxLoginData }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const handleLogout = () => {
        localStorage.removeItem('token');
        dispatch(getUnlogged());
        window.location.href = "/";
    }
    const isPosition = useSelector((state) => state.position.isPosition);
    const userLat = useSelector((state) => state.position.lat);
    const userLng = useSelector((state) => state.position.lng);
    const userCountry = useSelector((state) => state.position.country);

    // Handle insert phone number
    const token = localStorage.getItem('token');
    const [isSetNumber, setIsSetNumber] = useState(false);
    const [fetchStatus, setFetchStatus] = useState('idle');
    const [newNumber, setNewNumber] = useState(reduxLoginData.phone);
    const handleNewNumber = (event) => {
        const value = parseInt(event.target.value, 10)
        setNewNumber(value)
    }
    const sendNewNumber = async () => {
        try {
            setFetchStatus('pending');
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/users/set-phone-number`, {
                method: 'PATCH',
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ userId: reduxLoginData.id, phoneNumber: newNumber })
            });
            if (response.ok) {
                setFetchStatus('succeeded');
                backToHome();
            } else {
                const error = await response.json()
                console.log('Fetch error: ', error)
                setFetchStatus('rejected')
            }
        } catch (error) {
            console.log('Catch error: ', error)
            setFetchStatus('rejected')
        }
    }
    const backToHome = () => {
        setTimeout(() => {
            window.location.href = "/"
        }, 3000)
    }

    // handle upload image
    const [uploadedImage, setUploadedImage] = useState('');
    const fileInputRef = useRef(null);

    const handleInputImage = () => {
        fileInputRef.current.click()
    }

    const handleUploadImage = (event) => {
        const file = event.target.files[0];
        transformFile(file);
    }

    const transformFile = (file) => {
        const reader = new FileReader();
        if (file) {
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                setUploadedImage(reader.result)
            }
        } else {
            setUploadedImage('');
        }
    }

    const removeImage = () => {
        setUploadedImage('');
        if (fileInputRef.current) {
            fileInputRef.current.value = null
        }
    }

    const [imageFetchStatus, setImageFetchStatus] = useState('idle');
    const sendImage = async () => {
        const data = {userId : reduxLoginData.id, newAvatar: uploadedImage};
        try {
            setImageFetchStatus('pending')
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/users/edit-avatar`, {
                method: 'PATCH',
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            });
            if (response.ok) {
                const result = await response.json();
                setImageFetchStatus('succeeded');
            } else {
                const errorResult = await response.json();
                console.log('Fetch error: ', errorResult);
                setImageFetchStatus('rejected')
            }
        } catch (error) {
            console.log('Catch error: ', error)
            setImageFetchStatus('rejected')
        }
    }
    useEffect(() => {
        if (imageFetchStatus === 'succeeded') {
            setTimeout(() => {
                window.location.reload();
            }, 3000)
        }
    }, [imageFetchStatus])

    return (
        <div className="flex flex-col w-full border-2 p-4 gap-4 items-start">

            {/* No position alert */}
            {
                isPosition &&
                (userLat === "" || userLng === "") &&
                <div className="w-full bg-red-300 border-2 border-teal-500 p-2">
                    <p>La <b>posizione di base dei tuoi prodotti non è definita</b>. Se non la definisci essi non verranno geolocalizzati e sarà quindi meno immediato trovarli da parte degli altri utenti.</p>
                    <Link to="/set-products-position"><TealButton text="Fissa una posizione per i tuoi prodotti" /></Link>
                </div>
            }

            <h3 className='self-auto md:self-start text-teal-500 font-normal mb-4'>I tuoi dati:</h3>
            <ProfileUserAvatar url={`${uploadedImage ? uploadedImage : reduxLoginData.avatarUrl}`} click={handleInputImage} />
            <input
                type="file"
                accept="image/"
                id="photo"
                ref={fileInputRef}
                style={{ display: 'none' }}
                onChange={handleUploadImage} />
            {
                uploadedImage && imageFetchStatus === 'idle' &&
                <div className="flex gap-2">
                    <PrimaryButton text="Salva foto" click={sendImage} />
                    <SecondaryButton text="Annulla" click={() => setUploadedImage('')} />
                </div>
            }
            {imageFetchStatus === 'pending' && <div className="ml-8"><FetchLoader /></div>}
            {imageFetchStatus === 'succeeded' && <h3 className="text-teal-500">Avatar caricato con successo, attendi il refresh della pagina.</h3>}
            {imageFetchStatus === 'rejected' && <h3 className="text-red-500">Qualcosa è andato storto, ricarica la pagina e riprova.</h3>}
            <div className="mb-[-20px]">il tuo ID:</div>
            <h3>{reduxLoginData.id}</h3>

            <div className="flex gap-4 items-center">
                <div>Nome</div>
                <div className="p-2 border"><b>{reduxLoginData.name}</b></div>
            </div>
            <div className="flex gap-4 items-center">
                <div>Cognome</div>
                <div className="p-2 border"><b>{reduxLoginData.surname}</b></div>
            </div>
            <div className="flex flex-col md:flex-row gap-4 items-center">
                <div>Email</div>
                <div className="p-2 border"><b>{reduxLoginData.email}</b></div>
            </div>
            <div className="flex gap-4 items-center">
                <div>Password</div>
                <div className="p-2 border"><b>**********</b></div>
            </div>
            <div className="flex flex-col gap-4 items-start text-left border p-2">
                <div>Numero di telefono:</div>
                {!reduxLoginData.phone && <h3 className="text-teal-500">Non hai un numero di telefono impostato</h3>}
                {!reduxLoginData.phone && <h4>Impostare il numero di telefono è utile per facilitare lo scambio quando si acquista o si vende un prodotto. Esso viene mostrato SOLO all'utente con cui effettui lo scambio.</h4>}
                {!reduxLoginData.phone && !isSetNumber && <TealButton text="Imposta numero di telefono" click={() => setIsSetNumber(true)} />}
                {!reduxLoginData.phone && isSetNumber && fetchStatus === 'idle' &&
                    <div className="flex flex-col gap-2">
                        <input type="number" onChange={handleNewNumber}></input>
                        <div className="flex gap-2">
                            <PrimaryButton text="Invia" click={sendNewNumber} />
                            <SecondaryButton text="Annulla" click={() => setIsSetNumber(false)} />
                        </div>
                    </div>}
                {!reduxLoginData.phone && fetchStatus === 'pending' && <FetchLoader />}
                {!reduxLoginData.phone && fetchStatus === 'succeeded' && <h3>Numero di telefono aggiornato. Verrai reindirizzato fra 3 secondi.</h3>}
                {!reduxLoginData.phone && fetchStatus === 'rejected' && <h3>Qualcosa è andato storto, ricarica la pagina e riprova.</h3>}
                {reduxLoginData.phone && !isSetNumber &&
                    <div className="flex gap-2 items-center">
                        <b>{reduxLoginData.phone}</b>
                        <i className="fi fi-rr-pen-square text-3xl text-slate-300 mb-[-10px] cursor-pointer" onClick={() => setIsSetNumber(true)}></i>
                    </div>}
                {reduxLoginData.phone && isSetNumber && fetchStatus === 'idle' &&
                    <div className="flex flex-col gap-2">
                        <input type="number" placeholder={reduxLoginData.phone} onChange={handleNewNumber}></input>
                        <div className="flex gap-2">
                            <PrimaryButton text="Invia" click={sendNewNumber} />
                            <SecondaryButton text="Annulla" click={() => setIsSetNumber(false)} />
                        </div>
                    </div>
                }
                {reduxLoginData.phone && fetchStatus === 'pending' && <FetchLoader />}
                {reduxLoginData.phone && fetchStatus === 'succeeded' && <h3>Numero di telefono aggiornato. Verrari reindirizzato fra 3 secondi.</h3>}
                {reduxLoginData.phone && fetchStatus === 'rejected' && <h3>Qualcosa è andato storto, ricarica la pagina e riprova.</h3>}
            </div>

            {/* Di queste due sezioni dovresti farne dei componenti */}
            <div className="flex flex-col gap-4 items-start w-full border p-2">
                <div id="basePosDiv">La tua posizione:</div>
                {
                    isPosition &&
                    userLat !== '' &&
                    userLng !== '' &&
                    <div className="flex flex-col w-full">
                        <div className="p-2 border"><b>{userLat}, {userLng}</b></div>
                        <p className="text-teal-500">{userCountry}</p>
                    </div>
                }
                {
                    isPosition &&
                    (userLat === '' || userLng === '') &&
                    <h3 className="text-teal-500">Non rilevata</h3>
                }
                {
                    !isPosition &&
                    <FetchLoader />
                }
            </div>
            <div className="flex flex-col gap-4 items-start w-full border p-2">
                <div id="basePosDiv">La posizione dei tuoi prodotti:</div>
                {
                    isPosition &&
                    userLat !== '' &&
                    userLng !== '' &&
                    <div className="flex flex-col w-full">
                        <div className="p-2 border"><b>{reduxLoginData.basePosition.lat}, {reduxLoginData.basePosition.lng}</b></div>
                        <p className="text-teal-500">{reduxLoginData.basePosition.country}</p>
                        <Link to="/set-products-position"><TealButton text="Modifica posizione prodotti" width="100%" /></Link>
                    </div>
                }
                {
                    isPosition &&
                    (userLat === '' || userLng === '') &&
                    <div className="flex flex-col w-full items-start">
                        <h3 className="text-teal-500">Non rilevata</h3>
                        <Link to="/set-products-position"><TealButton text="Imposta posizione prodotti" width="100%" /></Link>
                    </div>
                }
                {
                    !isPosition &&
                    <FetchLoader />
                }
            </div>

            <div className="border p-2 w-full mt-16">
                <SecondaryButton text="Esegui il logout" click={handleLogout} />
            </div>
        </div>
    )
}
export default MyUserData;