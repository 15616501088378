import logo from '../images/logo-500.png'

const Footer = () => {
    return (
        <div className="bg-slate-700 text-slate-50 flex flex-col items-center p-12 md:px-48 xl:px-96 mb-24 md:mb-0">
            <h3 className='text-slate-50'>Cos'è Tattooswap?</h3>
            <p className='text-center text-slate-50'>Tattooswap è una piattaforma di <span className='text-teal-500'>compravendita di articoli usati per tatuatori</span>. Se sei un tatuatore professionista (o un apprendista), sarai spesso in cerca di qualcosa che può servirti o potresti avere in studio qualcosa di cui sbarazzarti.</p>
            <p className='text-center text-slate-50'>Molte persone preferiscono acquistare prodotti nuovi, ma se non è strettamente necessario, noi cerchiamo di incentivare lo scambio di prodotti in buono stato di seconda mano. Tattooswap ti aiuta a trovare attrezzatura che stai cercando nelle vicinanze, in modo completamente gratuito.</p>
            <p className='text-center text-slate-50'>Essendo un marketplace <span className='text-teal-500'>geolocalizzato</span> favorisce gli scambi fra utenti privati che si trovano più vicino uno all'altro.</p>
            <p className='text-center text-slate-50'>Potrebbe esserci un tuo collega non distante da te che sta vendendo quella tattoo pen che cerchi da tempo...</p>
            <div className='flex items-center gap-4'>
            <i class="fi fi-br-hand-back-point-right text-white text-xl mb-[-10px]"></i>
                <div className='my-2 p-4 border border-slate-500 rounded text-slate-50 italic'>Evita di comprare inutilmente attrezzatura nuova, il nostro settore è stracolmo di articoli che possono essere acquistati usati.</div>
            </div>
            <div className='flex items-center gap-4'>
            <i class="fi fi-br-hand-back-point-right text-white text-xl mb-[-10px]"></i>
                <div className='my-2 p-4 border border-slate-500 rounded text-slate-50 italic'>Metti in vendita anche tu le cose che non usi in studio.</div>
            </div>

            <i class="fi fi-rr-hand-holding-seeding text-teal-200 text-6xl mt-8"></i>
            <h3 className='text-center text-teal-200'>Favorisci il riciclo delle cose, da' nuova vita ad attrezzatura che non usi e contribuisci a ridurre gli sprechi.</h3>
            <div className="mt-12 mb-16 md:mb-0 text-black">Powered by<span className="font-bold ml-1 text-slate-50">Tattooswap</span></div>
        </div>
    )
}

export default Footer;