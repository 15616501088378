import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { selectPage } from "../redux/pageSlice";

export const PrimaryButton = ({ text, click, width }) => {
	return (
		<button className={`bg-slate-500 text-slate-50 px-4 py-3 rounded-lg hover:bg-slate-600 ${width === '100%' ? 'w-full' : 'min-w-[150px]'}`} onClick={click}>{text}</button>
	)
};

export const SecondaryButton = ({ text, click, width }) => {
	return (
		<button className={`py-3 px-3 text-slate-700 border-2 border-slate-200 rounded-lg hover:bg-slate-200 ${width === '100%' ? 'w-full' : 'min-w-[150px]'}`} onClick={click}>{text}</button>
	)
};

export const TealButton = ({ text, click, width }) => {
	return (
		<button className={`bg-teal-400 text-slate-50 px-4 py-3 rounded-lg hover:bg-teal-500 ${width === '100%' ? 'w-full' : 'min-w-[150px]'}`} onClick={click}>{text}</button>
	)
};

export const DangerButton = ({ text, click, width }) => {
	return (
		<button className={`bg-red-400 text-slate-50 px-4 py-3 rounded-lg hover:bg-red-500 ${width === '100%' ? 'w-full' : 'min-w-[150px]'}`} onClick={click}>{text}</button>
	)
};

export const DisabledButton = ({ text, width }) => {
	return (
		<button className={`py-3 px-3 text-slate-300 border-2 border-slate-200 rounded-lg cursor-not-allowed ${width === '100%' ? 'w-full' : 'min-w-[150px]'}`}>{text}</button>
	)
}


export const GoHomeButton = (click) => {
	const dispatch = useDispatch();
	const [query, setQuery] = useSearchParams();
	const backlink = query.get('backlink');
	return (
		<Link to={backlink ? `/${backlink}` : "/"}><div className="flex justify-center items-center border-2 rounded-lg p-4 cursor-pointer bg-white" onClick={() => dispatch(selectPage(0))}>
			<i class="fi fi-rr-arrow-small-left text-3xl mb-[-8px]"></i>
			<h4>Torna alla home</h4>
		</div></Link>
	)
}

export const GoConversationsButton = (click) => {
	const navigate = useNavigate();
	return (
		<Link to="/inbox"><div className="flex justify-center items-center border-2 rounded-lg p-4 cursor-pointer bg-white">
			<i class="fi fi-rr-arrow-small-left text-3xl mb-[-8px]"></i>
			<h4>Torna alle tue conversazioni</h4>
		</div></Link>
	)
}

export const ProductsButton = ({ text, click, width, selectedSection }) => {
	return (
		<button className={`${selectedSection ? 'bg-slate-500 text-slate-50 cursor-default' : 'bg-slate-50 text-slate-700 md:hover:bg-slate-200'}
		lg:h-20
		px-4
		py-3
		rounded-l
		${width === '100%' ? 'w-full' : 'w-[300px]'}
		border`}
			onClick={click}>
			{text}
		</button>
	)
};
