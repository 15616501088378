import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getCountryFunction } from "../components/utils/getCountryFunction";

export const getPositionAsync = createAsyncThunk(
    'position/getPosition',
    async (_, { dispatch }) => {
        dispatch(setIsLoading(true));
        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition(
                function (position) {
                    const positionData = { latitude: position.coords.latitude, longitude: position.coords.longitude };
                    dispatch(setPosition(positionData));
                    dispatch(getCountryAsync(positionData));
                    dispatch(setIsLoading(false));
                },
                function (error) {
                    console.error("Errore nel rilevare la posizione", error);
                    const positionData = { latitude: '', longitude: '' };
                    dispatch(setPosition(positionData));
                    dispatch(setCountry(''));
                    dispatch(setIsLoading(false));
                }
            )
        }
    }
)

export const getCountryAsync = createAsyncThunk(
    'position/getCountry',
    async ({ latitude, longitude }, { dispatch }) => {
        try {
            let country = await getCountryFunction(latitude, longitude);
            if (!country) { country = '' }
            dispatch(setCountry(country));
            dispatch(setIsLoading(false));
        } catch (error) {
            console.error('Errore nel recupero del nome del paese: ', error);
            throw error;
            dispatch(setIsLoading(false));
        }
    }
);

const positionSlice = createSlice({
    name: 'position',
    initialState: {
        isPosition: false,
        lat: null,
        lng: null,
        country: null,
        isLoading: false
    },
    reducers: {
        setPosition: (state, action) => {
            state.lat = action.payload.latitude;
            state.lng = action.payload.longitude;
            state.isPosition = true;
        },
        setCountry: (state, action) => {
            state.country = action.payload;
        },
        emptyPosition: (state) => {
            state.isPosition = false;
            state.lat = null;
            state.lng = null
            state.country = null
        },
        setIsLoading: (state, action) => {
            state.isLoading = action.payload
        }
    }
})

export const { setPosition, emptyPosition, setCountry, setIsLoading } = positionSlice.actions;
export default positionSlice.reducer;