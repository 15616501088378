import { useEffect, useState } from 'react';
import { GoHomeButton, PrimaryButton, SecondaryButton } from '../components/Buttons.jsx';
import { Link, redirect, useLocation } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { login } from '../redux/loginSlice.js';
import FetchLoader from '../components/FetchLoader.jsx';


const LoginPage = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const redirectUrl = decodeURIComponent(location.state?.redirect);

    //----- HANDLE USER INPUT -----//

    const [inputData, setInputData] = useState({ email: '', password: '' });

    const handleInputData = (event) => {
        const { id, value } = event.target;

        setInputData(prevInputData => ({
            ...prevInputData,
            [id]: value
        }))

        if (inputData.email && inputData.password) { setCheckData(false) }
    }

    //----- HANDLE LOGIN REDUX FETCH -----//

    const { isLogged, isLoading, error } = useSelector((state) => state.login);
    const [checkData, setCheckData] = useState(false);

    const sendData = () => {
        if (!inputData.email || !inputData.password) {
            setCheckData(true);
        } else {
            dispatch(login(inputData));
        }
    }

    useEffect(() => {
        if (isLogged) {
            setTimeout(() => {
                if (redirectUrl !== 'undefined') {
                    window.location.href = redirectUrl;
                } else {
                    window.location.href = "/";
                }
            }, 3000)
        }
    }, [isLogged])

    return (
        <div className="main-container">
            {!isLogged && <GoHomeButton />}
            <div className="box md:shadow-2xl sm:w-[400px]">

                {
                    isLogged ? (
                        <>
                            <h2 className='text-teal-500'>Bentornat* su Tattooswap!</h2>
                            <h3>Verrai indirizzat* alla pagina desiderata fra 3 secondi...</h3>
                        </>
                    ) : (
                        error ? (
                            error === 'Failed to fetch' ? (
                                <>
                                    <h4 className='mb-4'>Qualcosa è andato storto...</h4>
                                    <SecondaryButton text="Ricarica la pagina" click={() => window.location.reload(true)} />

                                </>
                            ) : (
                                <>
                                    <h4 className='mb-4'>{error}</h4>
                                    <SecondaryButton text="Ricarica la pagina" click={() => window.location.reload(true)} />
                                </>
                            )
                        ) : (
                            <>
                                <h2 className="mb-4 text-teal-500">Esegui il login</h2>
                                <label htmlFor="email">Indirizzo email</label>
                                <input type="text" id="email" onChange={handleInputData} />
                                <label htmlFor="password" >Password</label>
                                <input type="password" id="password" className="mb-8" onChange={handleInputData} />
                                <p className={checkData ? 'text-red-500 mb-4' : 'hidden'}>Riempi tutti i campi.</p>
                                {
                                    isLoading ? (
                                        <FetchLoader />
                                    ) : (
                                        <>
                                            <PrimaryButton text='Accedi' click={sendData} />
                                            <Link to="/recovery-password"><p className='text-teal-500 underline'>Ho dimenticato la password</p></Link>
                                        </>
                                    )
                                }

                                <div className="border-t mt-8 w-full p-2 text-slate-500">Nuovo su Tattooswap? <Link to='/register'><span className="text-teal-400 underline cursor-pointer">Registrati</span></Link></div>

                            </>
                        )
                    )
                }

            </div>
        </div>
    )
}

export default LoginPage;
