import tattooSwapLineLogo from '../images/tattooswap_line.png';
import { PrimaryButton, SecondaryButton } from './Buttons';

const CookiesAlert = ({ cookie }) => {
    return (
        <div className="fixed bottom-24 md:bottom-2 left-2 right-2 bg-teal-400 p-8 z-10 text-xs md:text-md">
            <img src={tattooSwapLineLogo} alt="logo" className='w-48' />
            <h3 className='mt-8'>Informativa</h3>
            <p>Tattooswap utilizza cookie tecnici o tecnologie simili per finalità tecniche di navigazione e, con il tuo consenso, anche per fintalità funzionali, esperienziali, di misurazione e marketing (con annunci personalizzati), meglio specificato nella <span className='underline'>cookie policy</span>. Il rifiuto del consenso può rendere non disponibili le relative funzioni.</p>
            <p>Puoi liberamente accettare, rifiutare o revocare il tuo consenso in qualsiasi momento.</p>
            <p>Usa il pulsante "Accetta tutto" per consentire. Usa i pulsante "Rifiuta tutto" per rifiutare.</p>
            <div className="flex flex-col gap-2 w-fit">
                <PrimaryButton text="Accetta tutto" click={() => cookie('yes')}/>
                <SecondaryButton text="Rifiuta tutto" click={() => cookie('no')} />
            </div>
        </div>
    )
}

export default CookiesAlert;