import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const getMapProducts = createAsyncThunk(
    'mapProducts/getMapProducts',
    async ({ lat, lng }) => {
        const url = `${process.env.REACT_APP_BASE_URL}/products/get-map-products`;
        const headers = { 'Content-Type': 'application/json' };
        const options = { method: 'POST', headers, body: JSON.stringify({ userLat: lat, userLng: lng }) };
        const response = await fetch(url, options);
        if (response.ok) {
            const result = await response.json();
            return result;
        } else {
            const error = await response.json();
            throw new Error(error.message || "Errore sconosciuto")
        }
    }
)

const mapProductsSlice = createSlice({
    name: 'mapProducts',
    initialState: {
        data: null,
        isLoading: false,
        error: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getMapProducts.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getMapProducts.fulfilled, (state, action) => {
                state.data = action.payload.payload;
                state.isLoading = false;
                state.error = null;
            })
            .addCase(getMapProducts.rejected, (state, action) => {
                state.error = action.error;
                state.isLoading = false;
            })
    }
})

export default mapProductsSlice.reducer;