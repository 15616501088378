import { Link, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import FetchLoader from '../components/FetchLoader.jsx';
import UserAvatar from "../components/UserAvatar.jsx";
import CategoryTag from '../components/CategoryTag.jsx';
import { GoHomeButton, PrimaryButton, SecondaryButton, TealButton } from "../components/Buttons.jsx";
import { useDispatch, useSelector } from "react-redux";
import { getDistance } from 'geolib';
import FetchMiniLoader from "../components/FetchMiniLoader.jsx";
import { setIsLoading } from "../redux/positionSlice.js";

const ProductPage = ({ }) => {
    const { productId, backlink } = useParams();
    // Dati prodotto
    const [productData, setProductData] = useState(null);
    const navigate = useNavigate();

    const isLogged = useSelector(state => state.login.isLogged);

    // FETCH FOR PRODUCT DATA
    const getProductDetails = async () => {
        try {
            let headers = {
                'Content-Type': 'application/json'
            };
            if (isLogged) {
                const token = localStorage.getItem('token');
                headers['Authorization'] = `Bearer ${token}`;
            }
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/products/${isLogged ? 'get-product' : 'get-shared-product'}`, {
                method: 'POST',
                headers: headers,
                body: JSON.stringify({ productId })
            })
            const productObject = await response.json();
            if (response.ok) {
                setProductData(productObject.payload)
            } else {
                console.log('object error: ', productObject)
                if (productObject.message === "Il token fornito non è valido o è scaduto") {
                    navigate("/login");
                }
            }
        } catch (error) {
            console.error('Catch error: ', error)
        }
    }

    useEffect(() => {
        getProductDetails()
    }, [])

    // Calcolate distance
    const { isPosition, lat, lng, isLoading } = useSelector(state => state.position);
    const [distance, setDistance] = useState(null);
    const [productNoDistance, setProductNoDistance] = useState(false);
    const dispatch = useDispatch();
    useEffect(() => {
        if (productData && isPosition) {
            dispatch(setIsLoading(true));
            let productLat = productData.position.lat;
            let productLng = productData.position.lng;
            if (productLat && productLng) {
                const calcDistance = getDistance(
                    { latitude: productData.position.lat, longitude: productData.position.lng },
                    { latitude: lat, longitude: lng }
                );
                setDistance(calcDistance);
            } else {
                setProductNoDistance(true);
            }
            dispatch(setIsLoading(false));
        }
    }, [productData, isPosition])

    // Handle buying
    const [isBuying, setIsBuying] = useState(false);
    const [fetchStatus, setFetchStatus] = useState('idle');
    const reduxPickerId = useSelector((state) => state.login.id)
    const token = localStorage.getItem('token');
    const sendRequest = async () => {
        try {
            setFetchStatus('pending');
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/products/send-request?productId=${productData._id}&pickerId=${reduxPickerId}`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });
            if (response.ok) {
                const result = await response.json();
                setFetchStatus('succeeded');
            } else {
                const errorData = await response.json()
                console.error('Errore nella richiesta.', errorData)
                setFetchStatus('error')
            }
        } catch (error) {
            console.error('Catch error: ', error)
            setFetchStatus('error')
        }
    }

    useEffect(() => {
        if (fetchStatus === 'succeeded') {
            setTimeout(() => {
                window.location.href = "/"
            }, 3000);
        };
        if (fetchStatus === 'error') {
            setTimeout(() => {
                setFetchStatus('idle')
            }, 3000);
        }
    }, [fetchStatus])

    // Handle share link
    const [linkCopied, setLinkCopied] = useState(false)
    const handleShareLink = () => {
        const link = `${process.env.REACT_APP_PUBLIC_URL}/shared-product/${productId}`;
        // Crea un elemento textarea nascosto
        const textarea = document.createElement('textarea');
        textarea.value = link;
        document.body.appendChild(textarea);
        // Seleziona e copia il testo nell'elemento textarea
        textarea.select();
        document.execCommand('copy');
        document.body.removeChild(textarea);
        setLinkCopied(true);
    }
    useEffect(() => {
        if (linkCopied) {
            setTimeout(() => {
                setLinkCopied(false)
            }, 3000)
        }
    }, [linkCopied])

    // Fromatted create date
    const [formattedDate, setFormattedDate] = useState('');
    const formatDate = () => {
        const date = new Date(productData.createdAt);
        const formattedDate = date.toLocaleString();
        setFormattedDate(formattedDate);
    }
    useEffect(() => {
        if (productData) {
            formatDate();
        }
    }, [productData])

    // Handle send message to supplier
    const [isInputOpen, setIsInputOpen] = useState(false);
    const openInput = () => {
        setIsInputOpen(true)
    }
    const closeInput = () => {
        setIsInputOpen(false)
    }
    const userId = useSelector(state => state.login.id);
    const [messageData, setMessageData] = useState({
        applicantId: null,
        supplierId: null,
        productId: null,
        message: ''
    });
    useEffect(() => {
        if (productData) {
            setMessageData(prevData => ({
                ...prevData,
                supplierId: productData.supplier._id,
                productId: productData._id
            }))
        }
    }, [productData])
    useEffect(() => {
        if (userId) {
            setMessageData(prevData => ({
                ...prevData,
                applicantId: userId
            }))
        }
    }, [userId])
    const handleInputMessage = (event) => {
        const { value } = event.target
        setMessageData(prevData => ({
            ...prevData,
            message: value
        }))
    }
    const [messageFetchStatus, setMessageFetchStatus] = useState('idle');
    const [error, setError] = useState(null);
    const sendMessage = async () => {
        try {
            setMessageFetchStatus('loading');
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/conversations/open-new-conversation`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(messageData)
            });
            if (response.ok) {
                setMessageFetchStatus('succeeded');
                window.location.href = "/inbox";
            } else {
                const errorResult = await response.json();
                setError(errorResult)
                setMessageFetchStatus('failed');
            }
        } catch (error) {
            setError(error);
            setMessageFetchStatus('failed');
        }
    }
    useEffect(() => {
        console.log(error)
    }, [error])
    return (
        <div className="main-container">
            {
                !isLogged &&
                <Link to={backlink || "/"}><div className="flex justify-center items-center border-2 rounded-lg p-4 cursor-pointer bg-white">
                    <i class="fi fi-rr-arrow-small-left text-3xl mb-[-8px]"></i>
                    <h4>Vai alla home</h4>
                </div></Link>
            }
            {
                !isLogged &&
                <Link to="/login"><TealButton text="Esegui il login" /></Link>
            }
            {isLogged && <GoHomeButton />}
            <div className="box">
                {
                    !productData ? (
                        <FetchLoader />
                    ) : (
                        <div className="flex flex-col">
                            {/* Title Section */}
                            <div className="flex justify-between">
                                <div className="flex gap-2 items-center">
                                    <UserAvatar url={productData.supplier.avatarUrl} />
                                    <div className="flex flex-col items-start">
                                        <h3>{productData.supplier.name} {productData.supplier.surname}</h3>
                                        {/* La parte seguente verrà sostituita dal sistema di recensioni utenti (stelline) */}
                                        {/* <div className="flex">
                                            <i className="fi fi-sr-star text-yellow-300"></i>
                                            <i className="fi fi-sr-star text-yellow-300"></i>
                                            <i className="fi fi-sr-star text-yellow-300"></i>
                                            <i className="fi fi-sr-star text-yellow-300"></i>
                                            <i className="fi fi-rr-star text-yellow-300"></i>
                                        </div> */}
                                    </div>
                                </div>

                                <div onClick={handleShareLink} className="relative">
                                    {!linkCopied && <i className="fi fi-rr-share text-xl md:text-3xl cursor-pointer"></i>}
                                    {linkCopied && <div className="absolute right-0 w-[200px] bg-teal-500 bg-opacity-80 border-2 rounded-lg p-1">
                                        <p className="text-sm mt-1">Link copiato.</p>
                                        <h3 className="text-white text-sm mt-[-10px]">Condividilo con chi vuoi!</h3>
                                    </div>}
                                </div>
                            </div>

                            {/* Image Info Section */}
                            {isLoading && <FetchMiniLoader />}
                            {distance && <p className='text-left mt-4'>A <span className='text-red-400 font-bold'>{Math.round(distance / 1000)}</span> km da te.</p>}
                            {(productNoDistance || !distance) && !isLoading && <p className="text-start text-red-400 italic">Distanza del prodotto non rilevata</p>}

                            <h4 className="self-start">ID prodotto: {productData._id}</h4>
                            <div className="my-2">
                                <img src={productData.photo.secure_url} alt="product" className="w-[300px] md:w-[600px] rounded-3xl" />
                            </div>

                            {/* Details Section */}
                            <div className="flex flex-col items-start text-left">
                                <h2>{productData.price.toFixed(2)}€</h2>
                                <h3>{productData.title}</h3>
                                <CategoryTag cat={productData.category} />
                                {formattedDate && <p>Oggetto inserito il: {formattedDate}</p>}
                                <p>Condizioni oggetto: <strong>{productData.wornStatus}</strong></p>
                                <hr className="text-black h-[1px] w-full ml-0" />
                                <p>{productData.description}</p>
                                <hr className="text-black h-[1px] w-full ml-0" />

                            </div>

                            {/* User Action */}
                            {
                                isLogged && !isBuying ? (
                                    <div className="flex flex-col gap-2">
                                        <TealButton text="Acquista questo oggetto" click={() => setIsBuying(true)} />
                                        <p>oppure</p>
                                        {!isInputOpen && <SecondaryButton text="Fa' una domanda al proprietario dell'oggetto" click={openInput} />}
                                        {
                                            isInputOpen &&
                                            <div className="flex flex-col gap-2">
                                                <input type="text" onChange={handleInputMessage} />
                                                <div className="w-full flex justify-center gap-2">
                                                    {messageFetchStatus === 'idle' && <SecondaryButton text="Annulla" click={closeInput} />}
                                                    {messageFetchStatus === 'idle' && <TealButton text="Invia" click={sendMessage} />}
                                                    {messageFetchStatus === 'loading' && <FetchLoader />}
                                                    {
                                                        messageFetchStatus === 'succeeded' &&
                                                        <div>
                                                            <h3>Messaggio inviato.</h3>
                                                            <i className="fi fi-br-check text-teal-400 text-3xl"></i>
                                                        </div>
                                                    }
                                                    {
                                                        messageFetchStatus === 'failed' &&
                                                        <div>
                                                            <h3>Qualcosa è andato storto, ricarica la pagina e riprova</h3>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        }
                                    </div>
                                ) : (
                                    isLogged && <div className="bg-teal-100 p-4 border-2 border-teal-500 rounded flex flex-col items-center">
                                        <div className="text-center">
                                            <h4>Confermando invierai all'utente proprietario una richiesta per l'acquisto di quest'oggetto.</h4>
                                            <p>Se il proprietario dovesse accettare potrà utilizzare il tuo numero di telefono o la tua email per contattarti in modo che vi accordiate in privato per lo scambio e il pagamento.</p>
                                        </div>
                                        {
                                            fetchStatus === 'pending' ? (
                                                <FetchLoader />
                                            ) : (
                                                fetchStatus === 'idle' ? (
                                                    <div className="flex gap-2">
                                                        <TealButton text="Conferma la richiesta" click={sendRequest} />
                                                        <SecondaryButton text="Annulla" click={() => setIsBuying(false)} />
                                                    </div>
                                                ) : (
                                                    <>
                                                        {fetchStatus === 'succeeded' &&
                                                            <>
                                                                <h2>Richiesta di acquisto inviata</h2>
                                                                <p>Attendi che il proprietario dell'oggetto ti contatti per telefono o via email.</p>
                                                            </>
                                                        }
                                                        {
                                                            fetchStatus === 'error' &&
                                                            <>
                                                                <h3>Qualcosa è andato storto. Ricarica la pagina e riprova</h3>
                                                            </>
                                                        }
                                                    </>
                                                )
                                            )
                                        }
                                    </div>
                                )
                            }
                            {!isLogged && <Link to="/login"><TealButton text="Esegui il login" /></Link>}
                        </div>
                    )
                }

            </div>
        </div>
    )
}

export default ProductPage;