import { useEffect, useState } from "react";
import CategoryTag from "../CategoryTag.jsx";
import { DangerButton, DisabledButton, PrimaryButton, SecondaryButton } from "../Buttons.jsx";
import FetchLoader from '../FetchLoader.jsx';
import { useDispatch } from "react-redux";
import UserAvatar from "../UserAvatar.jsx";
import closeIcon from '../../images/icons/close.png';
import { updateAddingTags, updateRemovingTag } from "../../redux/loginSlice.js";

const MyProductCard = ({ productData }) => {
    // Handle user input
    const [toEdit, setToEdit] = useState('none');
    const [editValue, setEditValue] = useState(null);
    const edit = (parameter) => {
        setToEdit(parameter);
        setEditValue(productData[parameter]);
    }
    const handleInputChange = (event) => {
        const { id, value } = event.target;
        if (id === 'price') {
            setEditValue(parseFloat(value))
        } else {
            setEditValue(value)
        }
    }
    const closeEditor = () => {
        setToEdit('none');
        setEditValue('');
    }

    // Edit fetch
    const dispatch = useDispatch();
    const token = localStorage.getItem('token');
    const [fetchStatus, setFetchStatus] = useState('idle');
    const sendEdit = async () => {
        const object = { [toEdit]: editValue };
        try {
            setFetchStatus('pending');
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/products/edit-product?productId=${productData._id}&userId=${productData.supplier}`, {
                method: 'PATCH',
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(object)
            });
            if (response.ok) {
                const result = await response.json()
                setFetchStatus('succeeded');
            } else {
                const errorData = await response.json()
                console.error('Errore nella richiesta.', errorData)
                setFetchStatus('error')
            }
        } catch (error) {
            console.error('Catch error: ', error)
            setFetchStatus('error')
        }
    }

    useEffect(() => {
        if (fetchStatus === 'error') {
            setTimeout(() => {
                setFetchStatus('idle');
                setToEdit('none');
                setIsDeleting(false);
            }, 3000);
        };
        if (fetchStatus === 'succeeded' ||
            fetchStatus === 'succeeded-yes' ||
            fetchStatus === 'succeeded-no') {
            setTimeout(() => {
                window.scrollTo(0, 0);
                window.location.href = "/";
            }, 3000);
        };
    }, [fetchStatus])

    // Handle delete object
    const [isDeleting, setIsDeleting] = useState(false);
    const setDeletingOn = () => {
        setIsDeleting(true)
    }
    const setDeletingOff = () => {
        setIsDeleting(false)
    }
    const sendDelete = async () => {
        try {
            setFetchStatus('pending');
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/products/delete-product?productId=${productData._id}&userId=${productData.supplier}`, {
                method: 'DELETE',
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
            });
            if (response.ok) {
                const result = await response.json();
                setFetchStatus('idle');
            } else {
                const errorData = await response.json()
                console.error('Errore nella richiesta.', errorData)
                setFetchStatus('error')
            }
        } catch (error) {
            console.error('Catch error: ', error)
            setFetchStatus('error')
        }
    }

    // Handle add tags
    const [initialTags, setInitialTags] = useState(productData.tags);
    const [currentTags, setCurrentTags] = useState(productData.tags);
    const [isRepeat, setIsRepeat] = useState(false);

    const [tagsInputData, setTagsInputData] = useState({
        productId: productData._id,
        newTag: ''
    });
    const [isAddingTag, setIsAddingTag] = useState(false);
    const [tagsFetchStatus, setTagsFetchStatus] = useState('idle');
    const handleTagsInputData = (event) => {
        const { value } = event.target;
        setTagsInputData(prevData => ({
            ...prevData,
            newTag: value
        }))
    }
    const handleAnnullaButton = () => {
        setIsAddingTag(false);
        setTagsInputData({
            productId: productData._id,
            newTag: ''
        })
    }
    const sendTag = async () => {
        if (currentTags.includes(tagsInputData.newTag)) {
            setIsRepeat(true);
            setTimeout(() => {
                setIsRepeat(false)
            }, 2000)
        } else {
            setTagsInputData({
                productId: productData._id,
                newTag: ''
            })
            setTagsFetchStatus('pending');
            try {
                const response = await fetch(`${process.env.REACT_APP_BASE_URL}/products/add-tags`, {
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(tagsInputData)
                });
                if (response.ok) {
                    const result = await response.json();
                    console.log(result.payload.tags);
                    const updateTags = [...currentTags, tagsInputData.newTag];
                    setCurrentTags(updateTags);
                    setTagsFetchStatus('idle');
                    dispatch(updateAddingTags({productId: tagsInputData.productId, newTag: tagsInputData.newTag}));

                } else {
                    const errorResult = await response.json();
                    console.log('Fetch error: ', errorResult);
                    setTagsFetchStatus('error');
                }
            } catch (error) {
                console.log('Catch error: ', error)
            }
        }
    }

    // handle delete tag
    const handleDeleteTag = (event) => {
        setTagsFetchStatus('pending');
        const tags = currentTags;
        const { id } = event.target;
        const updateTags = tags.filter(element => element !== id);
        setCurrentTags(updateTags);
        sendDeleteTag(id);
    }

    const handleCancelDelete = () => {
        setIsAddingTag(false);
        setCurrentTags(initialTags);
    }
    const sendDeleteTag = async (id) => {
        try {
            const data = { productId: productData._id, tagToRemove: id };
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/products/remove-tags`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            });
            if (response.ok) {
                const result = await response.json();
                console.log(result.payload.tags);
                setTagsFetchStatus('idle');
                dispatch(updateRemovingTag({productId: data.productId, tagToRemove: data.tagToRemove}))

            } else {
                const errorResult = await response.json();
                console.log('Fetch error: ', errorResult);
                setTagsFetchStatus('error');
            }
        } catch (error) {
            console.log('Catch error: ', error)
        }
    }

    return (
        <div className="flex flex-col w-full border-2 py-8">

            <div className="flex flex-col md:flex-row p-4 w-full gap-4">
                {/* left-photo */}
                <div className="overflow-hidden w-24 h-24">
                    <img src={productData.photo.resizedUrl} alt="product photo" className="w-full h-full object-cover rounded-lg" />
                </div>

                {/* right-info */}
                <div className="text-left flex flex-col gap-2 w-full">
                    <h4>ID prodotto:</h4>
                    <h4>{productData._id}</h4>
                    <div className="text-sm text-teal-500 mb-[-10px]">Titolo prodotto</div>
                    <div className="flex justify-between items-center border p-2 gap-4">
                        {
                            toEdit !== 'title' ? (
                                <>
                                    {toEdit !== 'title' && <h3>{productData.title}</h3>}
                                    {toEdit !== 'title' && fetchStatus !== 'succeeded' && fetchStatus !== 'pending' && productData.saleStatus.status !== 'Required' && <i className="fi fi-rr-pen-square text-3xl text-slate-300 mb-[-10px] cursor-pointer" onClick={() => edit('title')}></i>}
                                </>
                            ) : (
                                fetchStatus === 'idle' ? (
                                    <div className="flex flex-col gap-4">
                                        <div>
                                            {toEdit === 'title' && <input type="text" id="title" value={editValue} onChange={handleInputChange} />}
                                        </div>
                                        <div className="flex gap-2">
                                            <SecondaryButton text="Annulla" click={closeEditor} />
                                            <PrimaryButton text="Invia modifiche" click={sendEdit} />
                                        </div>
                                    </div>
                                ) : (
                                    fetchStatus === 'pending' ? (
                                        <FetchLoader />
                                    ) : (
                                        <>
                                            {fetchStatus === 'succeeded' && <h4>Elemento modificato con successo. Attendere il refresh della pagina.</h4>}
                                            {fetchStatus === 'error' && <h4>Qualcosa è andato storto, riprova.</h4>}
                                        </>
                                    )
                                )

                            )
                        }
                    </div>
                    <div className="text-sm text-teal-500 mb-[-10px]">Categoria</div>
                    <div className="flex justify-between items-center border p-2 gap-4">
                        {
                            toEdit !== 'category' ? (
                                <>
                                    {toEdit !== 'category' && <CategoryTag cat={productData.category} />}
                                    {toEdit !== 'category' && fetchStatus !== 'succeeded' && fetchStatus !== 'pending' && productData.saleStatus.status !== 'Required' && <i className="fi fi-rr-pen-square text-3xl text-slate-300 mb-[-10px] cursor-pointer" onClick={() => edit('category')}></i>}
                                </>
                            ) : (
                                fetchStatus === 'idle' ? (
                                    <div className="flex flex-col gap-4">
                                        <div>
                                            {toEdit === 'category' &&
                                                <select for="option" id="category" value={editValue} onChange={handleInputChange}>
                                                    <option value="Macchinette" >Macchinette</option>
                                                    <option value="Attrezzatura" >Attrezzatura</option>
                                                    <option value="Arredamento" >Arredamento</option>
                                                    <option value="Altro" >Altro</option>
                                                </select>
                                            }
                                        </div>
                                        <div className="flex gap-2">
                                            <SecondaryButton text="Annulla" click={closeEditor} />
                                            <PrimaryButton text="Invia modifiche" click={sendEdit} />
                                        </div>
                                    </div>
                                ) : (
                                    fetchStatus === 'pending' ? (
                                        <FetchLoader />
                                    ) : (
                                        <>
                                            {fetchStatus === 'succeeded' && <h4>Elemento modificato con successo. Attendere il refresh della pagina.</h4>}
                                            {fetchStatus === 'error' && <h4>Qualcosa è andato storto, riprova.</h4>}
                                        </>
                                    )
                                )

                            )
                        }
                    </div>
                    <div className="text-sm text-teal-500 mb-[-10px]">Prezzo</div>
                    <div className="flex justify-between items-center border p-2 gap-4">
                        {
                            toEdit !== 'price' ? (
                                <>
                                    {toEdit !== 'price' && <h4>{productData.price}</h4>}
                                    {toEdit !== 'price' && fetchStatus !== 'succeeded' && fetchStatus !== 'pending' && productData.saleStatus.status !== 'Required' && <i className="fi fi-rr-pen-square text-3xl text-slate-300 mb-[-10px] cursor-pointer" onClick={() => edit('price')}></i>}
                                </>
                            ) : (
                                fetchStatus === 'idle' ? (
                                    <div className="flex flex-col gap-4">
                                        <div>
                                            {toEdit === 'price' && <input type="number" id="price" value={editValue} onChange={handleInputChange} />}
                                        </div>
                                        <div className="flex gap-2">
                                            <SecondaryButton text="Annulla" click={closeEditor} />
                                            <PrimaryButton text="Invia modifiche" click={sendEdit} />
                                        </div>
                                    </div>
                                ) : (
                                    fetchStatus === 'pending' ? (
                                        <FetchLoader />
                                    ) : (
                                        <>
                                            {fetchStatus === 'succeeded' && <h4>Elemento modificato con successo. Attendere il refresh della pagina.</h4>}
                                            {fetchStatus === 'error' && <h4>Qualcosa è andato storto, riprova.</h4>}
                                        </>
                                    )
                                )

                            )
                        }
                    </div>
                    <div className="text-sm text-teal-500 mb-[-10px]">Condizioni prodotto</div>
                    <div className="flex justify-between items-center border p-2 gap-4">
                        {
                            toEdit !== 'wornStatus' ? (
                                <>
                                    {toEdit !== 'wornStatus' && <p>{productData.wornStatus}</p>}
                                    {toEdit !== 'wornStatus' && fetchStatus !== 'succeeded' && fetchStatus !== 'pending' && productData.saleStatus.status !== 'Required' && <i className="fi fi-rr-pen-square text-3xl text-slate-300 mb-[-10px] cursor-pointer" onClick={() => edit('wornStatus')}></i>}
                                </>
                            ) : (
                                fetchStatus === 'idle' ? (
                                    <div className="flex flex-col gap-4">
                                        <div>
                                            {toEdit === 'wornStatus' &&
                                                <select for="option" id="wornStatus" value={editValue} onChange={handleInputChange}>
                                                    <option value="Nuovo" >Nuovo</option>
                                                    <option value="In buono stato" >In buono stato</option>
                                                    <option value="In stato accettabile" >In stato accettabile</option>
                                                </select>
                                            }
                                        </div>
                                        <div className="flex gap-2">
                                            <SecondaryButton text="Annulla" click={closeEditor} />
                                            <PrimaryButton text="Invia modifiche" click={sendEdit} />
                                        </div>
                                    </div>
                                ) : (
                                    fetchStatus === 'pending' ? (
                                        <FetchLoader />
                                    ) : (
                                        <>
                                            {fetchStatus === 'succeeded' && <h4>Elemento modificato con successo. Attendere il refresh della pagina.</h4>}
                                            {fetchStatus === 'error' && <h4>Qualcosa è andato storto, riprova.</h4>}
                                        </>
                                    )
                                )

                            )
                        }
                    </div>
                    <div className="text-sm text-teal-500 mb-[-10px]">Descrizione</div>
                    <div className="flex justify-between items-center border p-2 gap-4">
                        {
                            toEdit !== 'description' ? (
                                <>
                                    {toEdit !== 'description' && <p>{productData.description}</p>}
                                    {toEdit !== 'description' && fetchStatus !== 'succeeded' && fetchStatus !== 'pending' && productData.saleStatus.status !== 'Required' && <i className="fi fi-rr-pen-square text-3xl text-slate-300 mb-[-10px] cursor-pointer" onClick={() => edit('description')}></i>}
                                </>
                            ) : (
                                fetchStatus === 'idle' ? (
                                    <div className="flex flex-col gap-4">
                                        <div>
                                            {toEdit === 'description' && <textarea id="description" value={editValue} onChange={handleInputChange} />}
                                        </div>
                                        <div className="flex gap-2">
                                            <SecondaryButton text="Annulla" click={closeEditor} />
                                            <PrimaryButton text="Invia modifiche" click={sendEdit} />
                                        </div>
                                    </div>
                                ) : (
                                    fetchStatus === 'pending' ? (
                                        <FetchLoader />
                                    ) : (
                                        <>
                                            {fetchStatus === 'succeeded' && <h4>Elemento modificato con successo. Attendere il refresh della pagina.</h4>}
                                            {fetchStatus === 'error' && <h4>Qualcosa è andato storto, riprova.</h4>}
                                        </>
                                    )
                                )

                            )
                        }
                    </div>

                    {/* Tags */}
                    <div className="text-sm text-teal-500 mb-[-10px]">Tags</div>
                    <div className="flex justify-start flex-wrap border p-2 gap-2">
                        {
                            currentTags.map((element, index) => {
                                    return <div key={index} className="p-2 bg-teal-100 rounded flex gap-2 items-center">{element}<img src={closeIcon} className="w-6 h-6 cursor-pointer" id={element} onClick={handleDeleteTag} /></div>
                            })
                        }
                    </div>
                    {
                        isAddingTag ? (
                            <>
                                {tagsFetchStatus === 'pending' && <FetchLoader />}
                                {
                                    tagsFetchStatus === 'idle' && <div className="flex flex-col gap-2">
                                        {!isRepeat && <input type="text" onChange={handleTagsInputData} />}
                                        {isRepeat && <h3 className="text-red-500">Tag esistente.</h3>}
                                        <div className="flex gap-2">
                                            <SecondaryButton text="Annulla" click={handleAnnullaButton} />
                                            {tagsInputData.newTag !== '' && <PrimaryButton text="Conferma" click={sendTag} />}
                                            {tagsInputData.newTag === '' && <DisabledButton text="Conferma" />}
                                        </div>
                                    </div>
                                }
                                {tagsFetchStatus === 'succeeded' && <h4>Tag aggiunto con successo. Attendi il refresh della pagina.</h4>}
                                {tagsFetchStatus === 'error' && <h4>Qualcosa è andato storto, riprova.</h4>}
                            </>
                        ) : (
                                <SecondaryButton text="Aggiungi tag" click={() => setIsAddingTag(true)} />
                        )
                    }

                    {/* Elimina prodotto buttons */}
                    <hr />
                    <div className="mt-8">
                        {

                            !isDeleting ? (
                                fetchStatus !== 'succeeded' && productData.saleStatus.status !== 'Required' && <DangerButton text="Elimina prodotto" click={setDeletingOn} />
                            ) : (
                                fetchStatus === 'pending' ? (
                                    <FetchLoader />
                                ) : (
                                    fetchStatus === 'idle' ? (
                                        <div className="flex flex-col">
                                            <h3>Sei sicur* di voler eliminare questo prodotto?</h3>
                                            <div className="flex gap-4">
                                                <PrimaryButton text="Annulla" click={setDeletingOff} />
                                                <DangerButton text="Elimina prodotto" click={sendDelete} />
                                            </div>
                                        </div>
                                    ) : (
                                        <>
                                            {fetchStatus === 'succeeded' && <h4>Prodotto eliminato con successo. Attendere il refresh della pagina.</h4>}
                                            {fetchStatus === 'error' && <h4>Qualcosa è andato storto, riprova.</h4>}
                                        </>
                                    )
                                )
                            )

                        }
                    </div>

                </div>
            </div>

        </div >
    )
}

export default MyProductCard;