import { useState, useEffect } from "react";
import FetchLoader from '../FetchLoader.jsx';
import UserAvatar from "../UserAvatar.jsx";
import { Link } from "react-router-dom";

const BuyedProductCard = ({ productData }) => {
    return (
        <div className='flex flex-col w-full border-2 p-4 gap-4'>
            <div className="flex flex-col md:flex-row gap-4">
                {/* left-photo */}
                <div className="overflow-hidden w-24 h-24">
                    <img src={productData.photo.resizedUrl} alt="product photo" className="w-full h-full object-cover rounded-lg" />
                </div>

                {/* right-info */}
                <div className="text-left flex flex-col gap-2 w-full">
                    <h4>ID prodotto:</h4>
                    <h4>{productData._id}</h4>
                    <div className="text-sm text-teal-500 mb-[-10px]">Titolo prodotto</div>
                    <div>{productData.title}</div>
                    <div className="text-sm text-teal-500 mb-[-10px]">Categoria</div>
                    <div>{productData.category}</div>
                    <div className="text-sm text-teal-500 mb-[-10px]">Prezzo</div>
                    <div>{productData.price}</div>
                    <div className="text-sm text-teal-500 mb-[-10px]">Condizioni prodotto</div>
                    <div>{productData.wornStatus}</div>
                    <div className="text-sm text-teal-500 mb-[-10px]">Descrizione</div>
                    <div>{productData.description}</div>
                </div>

            </div>

            {/* Message */}
            <div className="bg-teal-100 p-2 flex flex-col gap-1 items-center pt-6">
                <h4>Ecco i dati dell'utente che ti ha venduto questo oggetto.</h4>
                <p><i>Contattalo via email per accordarti sullo scambio e il pagamento.</i></p>
                <div className="text-sm text-teal-500 mb-[-10px]">Email proprietario:</div>
                <h3 className="text-teal-700">{productData.supplier.email}</h3>
                <div className="text-sm text-teal-500 mb-[-10px]">Numero di telefono:</div>
                {productData.supplier.phone ? (
                    <>
                        <h2 className="text-slate-50 bg-teal-500 rounded p-2 mt-2"><Link to={`https://wa.me/${productData.supplier.phone}`}>{productData.supplier.phone}</Link></h2>
                        <h4>Clicca sul numero di telefono per contattare l'utente</h4>
                    </>
                ) : (
                    <h3 className="text-teal-700">Il proprietario non ha impostato nessun numero</h3>
                )
                }
            </div>

            {/* Supplier data */}
            <div className="flex flex-col md:flex-row gap-4 border-2 border-teal-200 p-4 mt-[-15px]">
                {/* left-photo */}
                <div>
                    <UserAvatar url={productData.supplier.avatarUrl} />
                </div>

                {/* right-info */}
                <div className="text-left flex flex-col gap-2 w-full">
                    <h4>ID Utente:</h4>
                    <h4>{productData.supplier._id}</h4>
                    <div className="text-sm text-teal-500 mb-[-10px]">Nome</div>
                    <div>{productData.supplier.name}</div>
                    <div className="text-sm text-teal-500 mb-[-10px]">Cognome</div>
                    <div>{productData.supplier.surname}</div>
                    <div className="text-sm text-teal-500 mb-[-10px]">Si trova a</div>
                    <div>x km di distanza da te</div>

                </div>
            </div>

        </div>
    )
}

export default BuyedProductCard;