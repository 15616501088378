import { useSelector } from 'react-redux';
import SlideCardsContainer from '../components/cardsContainers/SlideCardsContainer.jsx';
import FetchLoader from '../components/FetchLoader.jsx';
import SetPositionBlock from '../components/SetPositionBlock.jsx';
import AdvBlock from '../components/AdvBlock.jsx';
import ShowDataBlock from '../components/ShowDataBlock.jsx';
import BaseProductsPanel from '../components/cardsContainers/BaseProductsPanel.jsx';
import slogan from '../images/slogan.png';
import logo from '../images/logo_300.png';
import { useEffect, useState } from 'react';
import desktopHero1 from '../images/hero/desktop_1.png';
import desktopHero2 from '../images/hero/desktop_2.png';
import desktopHero3 from '../images/hero/desktop_3.png';
import mobileHero1 from '../images/hero/mobile_1.png';
import mobileHero2 from '../images/hero/mobile_2.png';
import mobileHero3 from '../images/hero/mobile_3.png';
import SearchBar from '../components/SearchBar.jsx';
import ShowDataCustom from '../components/ShowDataCustom.jsx';
import HomepageCategoriesMenu from '../components/HomeCategoriesMenu.jsx';
import HomeCategorySection from '../components/HomeCategorySection.jsx';
import { Link } from 'react-router-dom';

const LoggedHomepage = () => {
    const { data, isLoading, error } = useSelector((state) => state.homepage);

    // handle Hero slide
    const [currentSlide, setCurrentSlide] = useState(0);
    const desktopHero = [
        desktopHero1,
        desktopHero2,
        desktopHero3
    ]
    const mobileHero = [
        mobileHero1,
        mobileHero2,
        mobileHero3
    ]
    useEffect(() => {
        switch (currentSlide) {
            case 0:
                setTimeout(() => {
                    setCurrentSlide(1);
                }, 7000)
                break;
            case 1:
                setTimeout(() => {
                    setCurrentSlide(2);
                }, 7000)
                break;
            case 2:
                setTimeout(() => {
                    setCurrentSlide(0);
                }, 7000)
                break;
            default:
                setTimeout(() => {
                    setCurrentSlide(0);
                }, 7000)
        }
    }, [currentSlide])
    return (
        <div className="main-container">

            {/* Old Hero */}
            {/* <h2 className='p-3'>Acquista e vendi ciò che viene accantonato in studio.</h2>
            <img src={logo} alt="logo" className='w-24 hidden md:block'/>
            <img src={slogan} alt="slogan" className='w-[300px] md:w-[400px] lg:w-[500px]'/>
            <h2 className='font-normal p-3'>Riduciamo insieme gli sprechi anche nel nostro settore!</h2> */}

            {/* Hero */}
            <div className='w-full hidden sm:flex justify-center'>
                <img src={desktopHero[currentSlide]} alt="hero image" />
            </div>
            <div className='w-full sm:hidden'>
                <img src={mobileHero[currentSlide]} alt="hero image" />
            </div>

            {/* Search bar */}
            <SearchBar isFromHome />


            {/* <HomeCategorySection /> */}

            {!isLoading && data && <ShowDataCustom />}

            {isLoading && <FetchLoader />}


            {!isLoading && error &&
                <div className='box'>
                    <h3>{error}</h3>
                </div>
            }


            {/* {<AdvBlock />} */}

            {/* <ShowDataBlock /> */}

            <Link to="/products-map"><button className='bg-teal-400 flex gap-2 items-center py-3 px-4 text-white rounded-lg'>
                <div><i className="fi fi-rr-map-marker"></i></div>
                <div>Visualizza la mappa</div>
            </button></Link>

            {!isLoading && data && <BaseProductsPanel title={"Ultimi prodotti inseriti"} array={data.lastProducts} />}

            {
                !isLoading && data &&
                <div className='w-full flex flex-col py-8 items-center'>
                    <SlideCardsContainer title={"Macchinette"} array={data.macchinette} filter="Macchinette" />
                    <SlideCardsContainer title={"Attrezzatura varia"} array={data.attrezzatura} filter="Attrezzatura" />
                    <SlideCardsContainer title={"Arredamento studio"} array={data.arredamento} filter="Arredamento" />
                    <SlideCardsContainer title={"Altro"} array={data.altro} filter="Altro" />
                </div>
            }







            {/* <AdvBlock /> */}
            <ShowDataCustom />
        </div >


    )
}

export default LoggedHomepage;