import { useEffect, useState } from "react";
import CategoryTag from "../CategoryTag.jsx";
import { DangerButton, PrimaryButton } from "../Buttons.jsx";
import FetchLoader from '../FetchLoader.jsx';
import UserAvatar from "../UserAvatar.jsx";

const MyRequiredProductCard = ({ productData }) => {
    const token = localStorage.getItem('token');
    const [fetchStatus, setFetchStatus] = useState('idle');

    useEffect(() => {
        if (fetchStatus === 'error') {
            setTimeout(() => {
                setFetchStatus('idle');
            }, 3000);
        };
        if (fetchStatus === 'succeeded-yes' ||
            fetchStatus === 'succeeded-no') {
            setTimeout(() => {
                window.scrollTo(0, 0);
                window.location.href = "/";
            }, 3000);
        };
    }, [fetchStatus])

    // Handle accept/deny request
    const sendResponse = async (decision) => {
        try {
            setFetchStatus('pending');
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/products/supplier-response?productId=${productData._id}&supplierId=${productData.supplier}&pickerId=${productData.saleStatus.pickerId}&decision=${decision}`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });
            if (response.ok) {
                if (decision) {
                    setFetchStatus('succeeded-yes');
                } else {
                    setFetchStatus('succeeded-no')
                }
            } else {
                const errorData = await response.json()
                console.error('Errore nella richiesta.', errorData)
                setFetchStatus('error')
            }
        } catch (error) {
            console.error('Catch error: ', error)
            setFetchStatus('error')
        }
    }

    // Handle update picker data
    const [pickerData, setPickerData] = useState({
        pickerId: productData.saleStatus.pickerId,
        pickerName: null,
        pickerSurname: null,
        pickerAvatar: null
    })
    const [pickerFetchStatus, setPickerFetchStatus] = useState('pending')
    const updatePickerIdData = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/users//get-user?userId=${productData.saleStatus.pickerId}`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });
            if (response.ok) {
                const result = await response.json();
                console.log('result: ', result);
                setPickerData(result.payload);
                setPickerFetchStatus('succeeded');
            } else {
                const error = await response.json();
                console.log('Fetch error: ', error)
                setPickerFetchStatus('rejected');
            }
        } catch (error) {
            console.log('Catch error: ', error);
            setPickerFetchStatus('rejected');
        }
    }
    useEffect(() => {
        updatePickerIdData();
    }, [])

    return (
        <div className={`flex flex-col w-full border-2 py-8 ${productData.saleStatus.status === 'Required' ? 'bg-teal-100' : ''}`}>

            {productData.saleStatus.status === 'Required' && <div className="bg-teal-100 p-2 flex flex-col items-center gap-4 pb-4 mb-4">
                <h2>Hai ricevuto una richiesta di acquisto per questo prodotto!</h2>
                <h3>Ecco da chi:</h3>

                {/* Questo div è da sostituire con un componente user-info (da utlizzare identicao anche in ProductPage) */}
                <div className="flex flex-col items-center border-2 p-2 shadow-xl">
                    <h3>User ID: {productData.saleStatus.pickerId}</h3>
                    {pickerFetchStatus === 'pending' && <FetchLoader />}
                    {pickerFetchStatus === 'rejected' && <h3>Non è stato possibile caricare i dati dell'utente. Prova a ricaricare la pagina.</h3>}
                    {
                        pickerFetchStatus === 'succeeded' &&
                        <div className="flex gap-2 items-center">
                            <UserAvatar url={pickerData.avatarUrl} />
                            <p>{pickerData.name} {pickerData.surname}</p>
                        </div>
                    }
                </div>

                <h4><i>Ora sei liber* di decidere se accettare o rifiutare la richiesta</i></h4>
                <ul>
                    <li><span className="text-teal-700 font-bold">Se accetterai:</span> Ti verranno mostrati il <b>numero di telefono</b> e l'<b>email</b> dell'utente interessato all'acquisto, in questo modo potrai contattarlo e accordarti direttamente per organizzare lo scambio e il pagamento.</li>
                    <li><span className="text-red-300 font-bold">Se rifiuterai:</span> L'oggetto tornerà fra i tuoi prodotti in vendita.</li>
                </ul>
                {
                    fetchStatus === 'idle' ? (
                        <div className="flex flex-col md:flex-row gap-2">

                            <PrimaryButton text="Accetto di venderlo" click={() => sendResponse(true)} />
                            <DangerButton text="Non voglio venderlo" click={() => sendResponse(false)} />
                        </div>
                    ) : (
                        fetchStatus === 'pending' ? (
                            <FetchLoader />
                        ) : (
                            <>
                                {fetchStatus === 'succeeded-yes' &&
                                    <>
                                        <h3 className="text-teal-700">Vendita accettata.</h3>
                                        <h4>Visualizzerai i dati dell'utente nell'apposita sezione nel tuo profilo.</h4>
                                    </>}
                                {fetchStatus === 'succeeded-no' &&
                                    <>
                                        <h3 className="text-red-300">Vendita rifiutata.</h3>
                                        <h4>Attendi il refresh della pagina e visualizzerai il prodotto di nuovo in vendita.</h4>
                                    </>}
                                {fetchStatus === 'error' && <h4>Qualcosa è andato storto, riprova.</h4>}
                            </>
                        )
                    )
                }
            </div>
            }
            <div className="flex flex-col md:flex-row p-4 w-full gap-4">
                {/* left-photo */}
                <div className="overflow-hidden w-24 h-24">
                    <img src={productData.photo.resizedUrl} alt="product" className="w-full h-full object-cover rounded-lg" />
                </div>

                {/* right-info */}
                <div className="text-left flex flex-col gap-2 w-full">
                    <h4>ID prodotto:</h4>
                    <h4>{productData._id}</h4>
                    <div className="text-sm text-teal-500 mb-[-10px]">Titolo prodotto</div>
                    <div className="flex justify-between items-center border p-2 gap-4">
                        <h3>{productData.title}</h3>
                    </div>
                    <div className="text-sm text-teal-500 mb-[-10px]">Categoria</div>
                    <div className="flex justify-between items-center border p-2 gap-4">
                        <CategoryTag cat={productData.category} />
                    </div>
                    <div className="text-sm text-teal-500 mb-[-10px]">Prezzo</div>
                    <div className="flex justify-between items-center border p-2 gap-4">
                        <h4>{productData.price}</h4>
                    </div>
                    <div className="text-sm text-teal-500 mb-[-10px]">Condizioni prodotto</div>
                    <div className="flex justify-between items-center border p-2 gap-4">
                        <p>{productData.wornStatus}</p>
                    </div>
                    <div className="text-sm text-teal-500 mb-[-10px]">Descrizione</div>
                    <div className="flex justify-between items-center border p-2 gap-4">
                        <p>{productData.description}</p>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default MyRequiredProductCard;