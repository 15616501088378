import { useState, useEffect, useDebugValue } from "react";
import { useSelector, useDispatch } from "react-redux";
import { GoHomeButton, PrimaryButton, TealButton } from "../components/Buttons";
import PanelCardsContainer from "../components/cardsContainers/PanelCardsContainer";
import SearchBar from "../components/SearchBar";
import FetchLoader from '../components/FetchLoader';
import { getAllProducts } from "../redux/productsSlice";
import CategoryFilter from "../components/CategoryFilter";
import { pageUp } from "../redux/filtersSlice";
import ActiveFilters from "../components/ActiveFilters";
import ProductsAmount from "../components/ProductsAmount";

const AllProducts = () => {
    const { isLoading, error, data } = useSelector(state => state.products);
    const { filtersObject, page } = useSelector(state => state.filters);

    // Ogni volta che filtersObject (o page) cambiano, la chiamata viene rieffettuata
    const dispatch = useDispatch();
    useEffect(() => {
        console.log('filtersObject: ', filtersObject)
        dispatch(getAllProducts({ filtersObject, page }))
    }, [filtersObject])

    useEffect(() => {
        if (page > 1) {
            dispatch(getAllProducts({ filtersObject, page }))
        }
    }, [page])

    const handleClick = () => {
        dispatch(pageUp())
    }

    return (
        <div className="main-container">
            <GoHomeButton />
            <SearchBar firstInput={filtersObject && filtersObject.search && filtersObject.search} />
            <CategoryFilter />

            {/* <ActiveFilters /> */}
            {/* Componente da sviluppare e implementare */}
            
            {
                data &&
                <ProductsAmount number={data.totalElements} />
            }


            {error && !isLoading && <h3>{error}</h3>}
            {data && data.products.length !== 0 && <PanelCardsContainer objectArray={data.products} />}
            {data && data.products.length === 0 && <h1>Nessun prodotto trovato</h1>}
            {/* <AdvBlock /> */}
            {isLoading && <FetchLoader />}
            {
                !isLoading &&
                data &&
                data.products.length > 0 &&
                data.products.length < data.totalElements && /* Il button spartisce quando sono stati raggiunti tutti i prodotti */
                <TealButton text="Mostra altro..." click={handleClick} />
            }

        </div>
    )
}

export default AllProducts;