import { useEffect, useState } from "react";
import { TealButton } from "../Buttons";
import FetchLoader from "../FetchLoader";

const MessageSendForm = ({ convId, senderId, receiverId }) => {

    // send message fetch
    const [dataToSend, setDataToSend] = useState({
        conversationId: convId,
        senderId: senderId,
        receiverId: receiverId,
        content: ''
    })
    const [fetchStatus, setFetchStatus] = useState('idle');
    const [error, setError] = useState(null);
    const token = localStorage.getItem('token')
    const sendMessage = async () => {
        try {
            setFetchStatus('loading')
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/conversations/send-new-message`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(dataToSend)
            });
            if (response.ok) {
                setFetchStatus('succeeded');
                    window.location.reload();
            } else {
                const errorResult = await response.json();
                setError(errorResult)
                setFetchStatus('failed');
            }
        } catch (error) {
            setError(error);
            setFetchStatus('failed');
        }
    }

    // handleInputData
    const handleInputData = (event) => {
        const { value } = event.target;
        setDataToSend(prevData => ({
            ...prevData,
            content: value
        }))
    }
    return (
        <div className="w-full p-2 md:w-[750px] md:p-0">
            <div className="flex flex-col md:flex-row gap-4 md:gap-2 items-center md:justify-center chatbox">
                {fetchStatus === 'idle' && <textarea onChange={handleInputData} />}
                {fetchStatus === 'idle' && <TealButton text="Invia" click={sendMessage}/>}
                {fetchStatus === 'loading' && <FetchLoader />}
                {fetchStatus === 'succeeded' && <i className="fi fi-br-check text-teal-400 text-3xl"></i>}
                {fetchStatus === 'failed' && <h3>Qualcosa è andato storto, ricarica la pagina e riprova</h3>}
            </div>
        </div>
    )
}

export default MessageSendForm;