import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";


// Nuova chiamata per la homepage (sostituisce quello sorpra)
// export const firstCall (controller già pronto in backend)
export const firstCall = createAsyncThunk(
    'products/firstCall',
    async () => {
        let url = `${process.env.REACT_APP_BASE_URL}/products/first-call`;
        let header = { 'Content-Type': 'application/json' };
        let options = { method: 'GET', header };
        const response = await fetch(url, options);
        if (response.ok) {
            const result = await response.json();
            return result;
        } else {
            const error = await response.json();
            throw new Error(error.message || 'Errore sconosciuto.')
        }
    }
)

const homepageSlice = createSlice({
    name: 'homepage',
    initialState: {
        data: null,
        isLoading: false,
        error: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(firstCall.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(firstCall.fulfilled, (state, action) => {
                state.data = action.payload.payload;
                state.isLoading = false;
                state.error = null;
            })
            .addCase(firstCall.rejected, (state, action) => {
                state.error = action.error;
                state.isLoading = false;
            })
    }
})


export default homepageSlice.reducer